import React from 'react';
import { Container, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
const Dashboard = () => {
    const appro_id = localStorage.getItem('appro_id');
    const {user} = useUser()
    if(appro_id)  window.location.href = `/partner/approved/${appro_id}`;
    return (
        <Container className="dashboard">
            <img src='/bg-dashboard.png' width="100%" alt='dashboard'/>
            <div className='btn-link'>
                <Link to="/partner/register" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" size="large">
                        Register Deal
                    </Button>
                </Link>
                <Link to="/partner/project" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" size="large" sx={{ width: 170 }}>
                        My Projects
                    </Button>
                </Link>
                {user.role !== 'SCN' ? (
                    <Link to="/partner/deals" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" size="large" sx={{ width: 170 }}>
                        Manage
                    </Button>
                </Link>
                ): ''}
                
            </div>
        </Container>

    )
}
export default Dashboard