import React from 'react';

const UnauthorizedPage = () => {
  return (
    <div className='authpage'>
        <img src='/403.jpg' alt='403'/>
      <h1>403 - Forbidden</h1>
      <p>Xin lỗi, bạn không có quyền truy cập trang này.</p>
    </div>
  );
};

export default UnauthorizedPage;
