

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BaseSevice from '../services/Baseservice';
import { useUser } from '../contexts/UserContext';


function useLogin() {
  const location = useLocation()
  const { setUser } = useUser()
  async function getProfile() {
    const token = localStorage.getItem('token');

    setUser(null)

    if (!token) {
      // No token found, log out the user
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      setUser(false)
      return;
    }

    try {
      const response = await BaseSevice.getAll('profile');

      // Token is valid and user info is fetched
      if (response.status === 200) {
        // Remove 'id' from response data if present
        // const { id, ...userInfo } = response.data;
        // localStorage.setItem('info', JSON.stringify(userInfo));
        setUser(response.data)
        return;
      }
    } catch (error) {
      // Handle errors (token might be invalid or other issues)
      console.error('Error checking login:', error);
    }

    // In case of error or invalid response, log out the user
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    setUser(false)
  }

  useEffect(() => {
    if (location.pathname.startsWith('/partner/approved')) {
      const id = location.pathname.substring('/partner/approved'.length + 1);
      if (id) {
        localStorage.setItem('appro_id', id);
      }
    }
    getProfile()
  }, [location.pathname])
}

export default useLogin;
