import React, { useEffect, useState } from 'react';
import { TextField, FormControl, Grid, Typography,  MenuItem } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import BaseSevice from "../services/Baseservice";
import { useUser } from '../contexts/UserContext';
import UnauthorizedPage from './UnauthorizedPage';
const columns = [
  { id: 'status', label: 'Status', minWidth: 170 },
  { id: 'projectName', label: 'Project Name', minWidth: 170 },
  { id: 'partnerName', label: 'Partner Name', minWidth: 170 },
  { id: 'companyName', label: 'Company Name', minWidth: 170 },
  { id: 'companyDomain', label: 'Company Domain', minWidth: 170 },
  { id: 'primarySaleName', label: 'Primary Sale Contact Name', minWidth: 170 },
  { id: 'primarySaleEmail', label: 'Primary Sale Contact Email', minWidth: 170 },
  { id: 'primarySalePhone', label: 'Primary Sale Contact Phone', minWidth: 170 },
  { id: 'customerCompany', label: 'End Customer Company', minWidth: 170 },
  { id: 'taxIndentification', label: 'Tax Indentification No', minWidth: 170 },
  { id: 'location', label: 'Location', minWidth: 170 },
  { id: 'repName', label: 'Rep Name', minWidth: 170 },
  { id: 'repRole', label: 'Rep Role', minWidth: 170 },
  { id: 'website', label: 'Website or Company Domain', minWidth: 170 },
  { id: 'keyProduct', label: 'Key Product', minWidth: 170 },
  { id: 'solution', label: 'Solution', minWidth: 170 },
  { id: 'description', label: 'Description', minWidth: 170 },
  { id: 'estimatedBudget', label: 'Estimated Project Budget', minWidth: 170 },
  { id: 'approvedBudget', label: 'Does the customer have approved budget?', minWidth: 170 },
  { id: 'requestPoc', label: 'Request POC', minWidth: 170 },
  { id: 'estimatedDate', label: 'Estimated Close Date', minWidth: 170 },
  { id: 'projectCompetitor', label: 'Project Competitor', minWidth: 170 },
  { id: 'ccto', label: 'CC', minWidth: 170 },
  { id: 'created', label: 'Created', minWidth: 170 },
 
];

function createData(id, projectName, partnerName, companyName, companyDomain, primarySaleName, primarySaleEmail, primarySalePhone, customerCompany, taxIndentification, location, repName,
  repRole, website, keyProduct, solution, description, estimatedBudget, approvedBudget, requestPoc, estimatedDate, projectCompetitor, ccto, status, created
) {
  return {
    id, projectName, partnerName, companyName, companyDomain, primarySaleName, primarySaleEmail, primarySalePhone, customerCompany, taxIndentification, location, repName,
    repRole, website, keyProduct, solution, description, estimatedBudget, approvedBudget, requestPoc, estimatedDate, projectCompetitor, ccto, status, created
  };
}
function formatDate(dateString) {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
  return new Date(dateString).toLocaleDateString('en-GB', options);
}
function formatBudget(value) {
  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
}  
function formatDomain(encodedString){
 return new DOMParser().parseFromString(encodedString, 'text/html').documentElement.textContent;
}  
export default function Project() {
  const {user} = useUser();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRowDetails, setSelectedRowDetails] = useState(null);
  const [leftWidth, setLeftWidth] = useState(12);
  const [rightWidth, setRightWidth] = useState(12);
  const [showDetail, setShowDetail] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const [statusFilter, setStatusFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await BaseSevice.getAll('deals');
        const apiData = response.data.registers || [];
        const transformedRows = apiData.map(item =>
          createData(item.id, item.projectName, item.partnerName, item.companyName, item.companyDomain, item.primarySaleName, item.primarySaleEmail, item.primarySalePhone, item.endCustomerCompany, item.taxIndentification,
            item.location, item.repName, item.repRole, item.website, item.keyProduct, item.solution, item.description, item.estimatedBudget, item.approvedBudget === 1 ? 'Yes' : 'No', item.requestPoc === 1 ? 'Yes' : 'No', item.estimatedCloseDate,
            item.projectCompetitor, item.ccto, item.status, item.created_at
          )
        );
        if (apiData.length >= 5) setShowPagination(true);
        setRows(transformedRows); // Cập nhật state với dữ liệu đã chuyển đổi
        setFilteredRows(transformedRows); // Cập nhật state với dữ liệu đã lọc
      } catch (err) {
        setError(err.message); // Xử lý lỗi
      } finally {
        setLoading(false); // Đặt trạng thái tải là false
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let filtered = rows;

    if (statusFilter) {
      filtered = filtered.filter(row => row.status === statusFilter);
    }

    if (startDate) {
      filtered = filtered.filter(row => new Date(row.estimatedDate) >= new Date(startDate));
    }

    if (endDate) {
      filtered = filtered.filter(row => new Date(row.estimatedDate) <= new Date(endDate));
    }

    setFilteredRows(filtered);
  }, [statusFilter, startDate, endDate, rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = async (id) => {
    try {
      const response = await BaseSevice.getById('deal', id);
      setSelectedRowDetails(response.data);
      setLeftWidth(9);
      setRightWidth(3);
      setShowDetail(true);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const getStatusClass = (status) => {
    switch (status) {
      case 'Approved':
        return 'status-approved';
      case 'Pending':
        return 'status-pending';
      case 'Rejected':
        return 'status-rejected';
      default:
        return '';
    }
  };
  const minEndDate = startDate ? new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1)).toISOString().split('T')[0] : '';
  if(user.role === 'PN' || user.role === 'SM' || user.role === 'Sale'){
    return (
        <UnauthorizedPage/>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={leftWidth}>
        <Paper>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12} md={3}>
              <TextField
                select
                label="Status"
                fullWidth
                value={statusFilter}
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                  setPage(0);
                }}
                size='small'
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                type="date"
                label="Start Date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setPage(0);
                  // Cập nhật giá trị min cho End Date khi Start Date thay đổi
                  if (endDate && new Date(endDate) < new Date(e.target.value)) {
                    setEndDate(e.target.value); // Nếu End Date nhỏ hơn Start Date, cập nhật End Date
                  }
                }}
                size='small'
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                type="date"
                label="End Date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setPage(0);
                }}
                InputProps={{ inputProps: { min: minEndDate } }}
                disabled={!startDate}
                size='small'
              />
            </Grid>
           
          </Grid>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.length > 0 ? (
                  filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i} onClick={() => handleRowClick(row.id)}>
                        {columns.map((column, i) => {
                           const value = column.id === 'created' || column.id === 'estimatedDate' ? formatDate(row[column.id]) : row[column.id];
                          return (
                            <TableCell key={i} align={column.align}>
                              {column.id === 'status' ? (
                                <span className={getStatusClass(value)}>{value}</span>
                              ):  column.id === 'website' ? (
                                formatDomain(row.website)
                              ) :  column.id === 'estimatedBudget' ? (
                                formatBudget(row.estimatedBudget)
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {showPagination ?
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            : ''
          }
        </Paper>
      </Grid>
      {showDetail ?
        <Grid item xs={12} md={rightWidth}>
          <FormControl fullWidth sx={{ marginBottom: 2, height: 645, overflowX: 'scroll', paddingTop: 2 }}>
            <Grid container spacing={2} alignItems="center" sx={{ paddingRight: 2 }}>
              <Grid item xs={12}>
                <Typography component="label">Project Name:</Typography>
                <TextField fullWidth name="projectName" value={selectedRowDetails?.projectName ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Partner Name:</Typography>
                <TextField fullWidth name="partnerName" value={selectedRowDetails?.partnerName ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Company Name:</Typography>
                <TextField fullWidth name="companyName" value={selectedRowDetails?.companyName ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Company Domain:</Typography>
                <TextField fullWidth name="companyDomain" value={selectedRowDetails?.companyDomain ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Primary Sale Contact Name:</Typography>
                <TextField fullWidth name="primarySaleName" value={selectedRowDetails?.primarySaleName ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Primary Sale Contact Email:</Typography>
                <TextField fullWidth name="primarySaleEmail" value={selectedRowDetails?.primarySaleEmail ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Primary Sale Contact Phone:</Typography>
                <TextField fullWidth name="primarySalePhone" value={selectedRowDetails?.primarySalePhone ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">End Customer Company:</Typography>
                <TextField fullWidth name="endCustomerCompany" value={selectedRowDetails?.endCustomerCompany ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Tax Indentification No:</Typography>
                <TextField fullWidth name="taxIndentification" value={selectedRowDetails?.taxIndentification ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Location:</Typography>
                <TextField fullWidth name="location" value={selectedRowDetails?.location ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Rep Name:</Typography>
                <TextField fullWidth name="repName" value={selectedRowDetails?.repName ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Rep Role:</Typography>
                <TextField fullWidth name="repRole" value={selectedRowDetails?.repRole ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Website or Company Domain:</Typography>
                <TextField fullWidth name="website" value={selectedRowDetails?.website ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Key Product:</Typography>
                <TextField fullWidth name="keyProduct" value={selectedRowDetails?.keyProduct ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Solution:</Typography>
                <TextField fullWidth name="solution" value={selectedRowDetails?.solution ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Description:</Typography>
                <TextField fullWidth name="description" value={selectedRowDetails?.description ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Estimated Project Budget:</Typography>
                <TextField fullWidth name="estimatedBudget" value={selectedRowDetails?.estimatedBudget ?? ''} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Does the customer have approved budget?</Typography>
                <TextField fullWidth name="approvedBudget" value={selectedRowDetails?.approvedBudget === 1 ? 'Yes' : 'No'} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Request POC?</Typography>
                <TextField fullWidth name="requestPoc" value={selectedRowDetails?.requestPoc === 1 ? 'Yes' : 'No'} size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Estimated Close Date</Typography>
                <TextField fullWidth name="estimatedCloseDate" value={formatDate(selectedRowDetails?.estimatedCloseDate) } size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Project Competitor</Typography>
                <TextField fullWidth name="projectCompetitor" value={formatDate(selectedRowDetails?.projectCompetitor) } size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">CC</Typography>
                <TextField fullWidth name="ccto" value={selectedRowDetails?.ccto } size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Created</Typography>
                <TextField fullWidth name="created_at" value={formatDate(selectedRowDetails?.created_at) } size="small" />
              </Grid>
              <Grid item xs={12}>
                <Typography component="label">Status</Typography>
                <TextField fullWidth name="status" value={selectedRowDetails?.status } size="small" />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        : ''
      }
    </Grid>
  );
}
