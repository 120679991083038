// src/apiClient.js
import axios from 'axios';
import { API_BASE_URL } from './config';
const apiClient = axios.create({
  baseURL: `${API_BASE_URL}`, // Thay đổi URL theo API của bạn
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  // error => Promise.reject(error)
);

apiClient.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post(`${API_BASE_URL}/refresh-token`, {
          refreshToken,
        });
        const token  = response.data.accessToken;
        const refreshTokenNews = response.data.refreshToken
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken',  refreshTokenNews);
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return apiClient(originalRequest);
      } catch (err) {
        // Xóa token và refresh token, và chuyển hướng đến trang đăng nhập
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');

        // Sử dụng history để chuyển hướng đến trang login
        window.location.href = '/'; // Chuyển hướng đến trang đăng nhập
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient;
