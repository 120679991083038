import React, { useEffect, useState } from 'react';
import { Grid, TextField, MenuItem} from '@mui/material';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import BaseSevice from "../services/Baseservice";
import { useUser } from '../contexts/UserContext';
import UnauthorizedPage from './UnauthorizedPage';
const columns = [
  { id: 'status', label: 'Status', minWidth: 170 },
  { id: 'projectName', label: 'Project Name', minWidth: 170 },
  { id: 'partnerName', label: 'Partner Name', minWidth: 170 },
  { id: 'companyName', label: 'Company Name', minWidth: 170 },
  { id: 'primarySaleName', label: 'Primary Sale Contact Name', minWidth: 170 },
  { id: 'primarySaleEmail', label: 'Primary Sale Contact Email', minWidth: 170 },
  { id: 'primarySalePhone', label: 'Primary Sale Contact Phone', minWidth: 170 },
  { id: 'customerCompany', label: 'End Customer Company', minWidth: 170 },
  { id: 'taxIndentification', label: 'Tax Indentification No', minWidth: 170 },
  { id: 'location', label: 'Location', minWidth: 170 },
  { id: 'repName', label: 'Rep Name', minWidth: 170 },
  { id: 'repRole', label: 'Rep Role', minWidth: 170 },
  { id: 'website', label: 'Website or Company Domain', minWidth: 170 },
  { id: 'keyProduct', label: 'Key Product', minWidth: 170 },
  { id: 'solution', label: 'Solution', minWidth: 170 },
  { id: 'description', label: 'Description', minWidth: 170 },
  { id: 'estimatedBudget', label: 'Estimated Project Budget', minWidth: 170 },
  { id: 'approvedBudget', label: 'Does the customer have approved budget?', minWidth: 170 },
  { id: 'estimatedDate', label: 'Estimated Close Date', minWidth: 170 },
  { id: 'pnmail', label: 'Partner', minWidth: 170 },
  { id: 'pnstatus', label: "Partner's Status", minWidth: 170 },
  { id: 'pnnote', label: "Partner's Note", minWidth: 170 },
  { id: 'smmail', label: 'Sale Manager', minWidth: 170 },
  { id: 'smstatus', label: "Sale Manger's Status", minWidth: 170 },
  { id: 'smnote', label: "Sale Manger's Note ", minWidth: 170 },
  { id: 'sale', label: 'Sale', minWidth: 170 },
  { id: 'created', label: 'Created', minWidth: 170 },
];

function createData(id, status, projectName, partnerName, companyName, primarySaleName, primarySaleEmail, primarySalePhone, customerCompany, taxIndentification, location, repName,
  repRole, website, keyProduct, solution, description, estimatedBudget, approvedBudget,  estimatedDate, pnmail, pnstatus, pnnote, smmail, smstatus, smnote, sale, created
) {
  return {
    id, status, projectName, partnerName, companyName,  primarySaleName, primarySaleEmail, primarySalePhone, customerCompany, taxIndentification, location, repName,
    repRole, website, keyProduct, solution, description, estimatedBudget, approvedBudget,  estimatedDate, pnmail, pnstatus, pnnote,smmail, smstatus, smnote, sale, created
  };
}
function formatDate(dateString) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  }
function formatBudget(value) {
    return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
  }  
function formatDomain(encodedString){
   return new DOMParser().parseFromString(encodedString, 'text/html').documentElement.textContent;
}  
function encodeBase64(id, email) {
    const stringToEncode = `${id}|${email}`;
    return btoa(stringToEncode);
  }  

export default function ManagerDeal() {
    const {user} = useUser();
   
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPagination, setShowPagination] = useState(false);
  const [statusFilter, setStatusFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await BaseSevice.getAll('deals');
        const apiData = response.data.registers || [];
        const transformedRows = apiData.map(item =>
          createData(item.id, item.status, item.projectName, item.partnerName, item.companyName, item.primarySaleName, item.primarySaleEmail, item.primarySalePhone, item.endCustomerCompany, item.taxIndentification,
            item.location, item.repName, item.repRole, item.website, item.keyProduct, item.solution, item.description, item.estimatedBudget, item.approvedBudget === 1 ? 'Yes' : 'No', item.estimatedCloseDate,
             item.pnmail ,item.status_partner, item.note_partner, item.smmail, item.status_salemn, item.note_salemn, item.finish, item.created_at
          )
        );
        setRows(transformedRows); // Cập nhật state với dữ liệu đã chuyển đổi
        setFilteredRows(transformedRows);
        if (apiData.length >= 5) setShowPagination(true);
      } catch (err) {
        setError(err.message); // Xử lý lỗi
      } finally {
        setLoading(false); // Đặt trạng thái tải là false
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let filtered = rows;

    if (statusFilter) {
      filtered = filtered.filter(row => row.status === statusFilter);
    }

    if (startDate) {
      filtered = filtered.filter(row => new Date(row.created) >= new Date(startDate));
    }

    if (endDate) {
      filtered = filtered.filter(row => new Date(row.created) <= new Date(endDate));
    }

    setFilteredRows(filtered);
  }, [statusFilter, startDate, endDate, rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const getStatusClass = (status) => {
    switch (status) {
      case 'Approved':
        return 'status-approved';
      case 'Pending':
        return 'status-pending';
      case 'Rejected':
        return 'status-rejected';
      default:
        return '';
    }
  };
  const minEndDate = startDate ? new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1)).toISOString().split('T')[0] : '';
  
  if(user.role === 'SCN' || user.role === 'Sale'){
    return (
        <UnauthorizedPage/>
    )
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Paper>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12} md={3}>
              <TextField
                select
                label="Status"
                fullWidth
                value={statusFilter}
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                  setPage(0);
                }}
                size="small"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                type="date"
                label="Start Date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => {
                    setStartDate(e.target.value);
                    setPage(0);
                    // Cập nhật giá trị min cho End Date khi Start Date thay đổi
                    if (endDate && new Date(endDate) < new Date(e.target.value)) {
                      setEndDate(e.target.value); // Nếu End Date nhỏ hơn Start Date, cập nhật End Date
                    }
                  }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                type="date"
                label="End Date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setPage(0);
                }}
                size="small"
                InputProps={{ inputProps: { min: minEndDate } }}
                disabled={!startDate}
              />
            </Grid>
            
          </Grid>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.length > 0 ? (
                  filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                        {columns.map((column, i) => {
                           const value = column.id === 'created' || column.id === 'estimatedDate' ? formatDate(row[column.id]) : row[column.id];
                        
                          return (
                            <TableCell key={i} align={column.align}>
                              {column.id === 'status' || column.id === 'pnstatus' ||  column.id === 'smstatus' ? (
                                 row.status === 'Pending' ? (
                                    <Link 
                                    to={`/partner/approved/${encodeBase64(row.id, user.email)}`} 
                                    className={getStatusClass(value)}
                                  >
                                    {value}
                                  </Link>
                                 ) : ( <span className={getStatusClass(value)}>{value}</span>)
                          
                              ):  column.id === 'website' ? (
                                formatDomain(row.website)
                              ) :  column.id === 'estimatedBudget' ? (
                                formatBudget(row.estimatedBudget)
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {showPagination ?
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            : ''
          }
        </Paper>
      </Grid>
    </Grid>
  );
}
