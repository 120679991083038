import React from "react";
import { Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useUser } from "../contexts/UserContext";

const Header = () => {
  const {user} = useUser()
  const handelLogout = () => {
    const confirmed = window.confirm('Are you sure you want to log out?');
    if (confirmed) {
      localStorage.clear();
      window.location.href = '/';
    }
  };
  let roleName = '';
  if(user.role === 'SCN') roleName = 'Sale Channels';
  if(user.role === 'PN') roleName = 'Partners';
  if(user.role === 'SM') roleName = 'Sale Managers';
  return (
    <Container className="header" maxWidth={false}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} md={1}>
          <Link to="/partner" style={{ textDecoration: 'none' }}>
            <img src="/logo-fptsmart-2.png" width="80px" alt="logo" />
          </Link>
        </Grid>
        <Grid item xs={4} md={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <h3>Partner Portal</h3>
          <p>Registration</p>
        </Grid>
        <Grid item xs={7} md={7} sx={{ textAlign: "right" }}>
          <p>Welcome {roleName}</p>
          <p><strong>{user?.name || ''}</strong></p>
          <p className="btn-logout" onClick={handelLogout} aria-hidden="true">Logout</p>
        </Grid>
      </Grid>
    </Container>

  )
}
export default Header