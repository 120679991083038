
import { API_BASE_URL } from '../config';
import apiClient  from '../apiClient';

const BaseSevice = {
  create: (resource, data) => apiClient.post(`${API_BASE_URL}/${resource}`, data),
  getAll: (resource) => apiClient.get(`${API_BASE_URL}/${resource}`),
  getById: (resource, id) => apiClient.get(`${API_BASE_URL}/${resource}/${id}`),
  update: (resource, id, data) => apiClient.put(`${API_BASE_URL}/${resource}/${id}`, data),
  delete: (resource, id) => apiClient.delete(`${API_BASE_URL}/${resource}/${id}`),
};

export default BaseSevice;
