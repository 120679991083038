// UserContext.js
import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';

const UserContext = createContext({
    user: null,
    setUser() { }
});

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    //   const [loading, setLoading] = useState(true);

    const value = useMemo(() => ({
        user,
        setUser,
    }), [user, setUser])
    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
