import React from "react";
import { URL_CALLBACK } from "../config";

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i += 1) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

const Login = (props) => {
  const clientId = '8b4d265c-64af-4d13-a7b1-25020c3e3101';
  const tenantId = 'f01e930a-b52e-42b1-b70f-a8882b5d043b';
  const responseType = 'code';
  const scope = 'user.read openid profile offline_access';
  const state = generateRandomString(32);
  localStorage.setItem('state', state);
  const authorizationUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${encodeURIComponent(URL_CALLBACK)}&scope=${encodeURIComponent(scope)}&state=${state}`;

  return (
    <div className="fci-box-login fc-box-center">
      <div className="logo"><img src="/logo-fptsmart.png" width="130px" alt="logo" /></div>
      <h4 className="fci-box-login-title">
        <i className="fa-solid fa-qrcode" />
        Partner Portal Application
      </h4>
      <div className="text-center mb-3">
        <a href={authorizationUrl}><img src="/btn-login.png" alt="login-buttom" /></a>
      </div>
    </div>
  );
};

export default Login;
