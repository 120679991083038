import useLogin from '../utils/WithPrivateRouter';
import { useEffect, useState } from 'react';
import {
    useLocation
} from "react-router-dom";
import { useUser } from '../contexts/UserContext';


const AuthLayout = (props) => {
    
    const {user} = useUser()
    useLogin()

    if (user === false) return props.navigate

    if (user) return props.children;

    return null;
};

export default AuthLayout;
