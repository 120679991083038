import {
  BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom';
import LoginMicrosoft from './components/LoginMicrosoft';
import './App.css';
import Login from './pages/Login';
import Layout from './parts/Layout';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Project from './pages/Project';
import AuthLayout from './parts/AuthLayout';
import Approved from './pages/Approved';
import { UserProvider } from './contexts/UserContext';
import ManagerDeal from './pages/ManagerDeal';
const App = () => {

  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/callback" element={<LoginMicrosoft />} />
          <Route path="/partner" element={<AuthLayout navigate={<Navigate to="/" />}><Layout /></AuthLayout>}>
            <Route path="register" element={<Register />} />
            <Route path="project" element={<Project />} />
            <Route path="deals" element={<ManagerDeal />} />
            <Route exact path="" element={<Dashboard />} />
            <Route path="approved/:id" element={<Approved />} />
          </Route>
          <Route exact path="/" element={<AuthLayout navigate={<Login />}><Navigate to="/partner" /></AuthLayout>} />
          <Route path="*" element={<AuthLayout navigate={<Navigate to="/" />}><Navigate to="/partner" /></AuthLayout>} />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  )
};

export default App;
