import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container,   Select, MenuItem, Grid,FormControl ,Typography} from '@mui/material';
import { useUser } from "../contexts/UserContext";
import BaseSevice from "../services/Baseservice";
const isValidBase64 = (str) => {
    // Biểu thức chính quy để kiểm tra chuỗi Base64 hợp lệ
    const base64Pattern = /^[A-Za-z0-9+/=]+$/;
    return base64Pattern.test(str) && (str.length % 4 === 0);
  };
const decodeBase64 = (base64String) => {
    if (!isValidBase64(base64String)) {
        // Nếu chuỗi không hợp lệ, chuyển hướng về trang chủ
        window.location.href = '/';
        return;
      }
    // Giải mã Base64 thành chuỗi nhị phân
    const binaryString = window.atob(base64String);
    // Chuyển đổi chuỗi nhị phân thành mã điểm Unicode
    const binaryArray = new Uint8Array([...binaryString].map(char => char.charCodeAt(0)));
    // Chuyển đổi mảng nhị phân thành chuỗi văn bản
    return new TextDecoder().decode(binaryArray);
  };
const Approved = () => {
    localStorage.removeItem('appro_id');
    const { id } = useParams();
    const {user} = useUser()
    const [shownote, setShowNote] = useState(false);
    const [note, setNote] = useState('');
    const [dealDetail, setDealDetail] = useState(null);
    const [saleLists, setSaleLists] = useState([{name: '--Chọn sale--', email: ''}]);
    const [sale, setSale] = useState(null);
    const [checkSuccess, setCheckSuccess] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('Đã phê duyệt');
    const result = decodeBase64(id);
    const [idRow, mail] = result.split('|');
    const [isProcessing, setIsProcessing] = useState(false);
    useEffect(()=>{
        const fetchData = async ()=>{
            try {
                const response = await BaseSevice.getById('deal', Number(idRow));
                setDealDetail(response.data);
                if(user.role === 'SM'){
                    const saleLists = await BaseSevice.create('sale', {department: response.data.productcode, location: response.data.locationcode})
                    setSaleLists(saleLists.data.saleLists);
                }
              
              } catch (err) {
              }
        }
        fetchData();
        const checkStatusDeals = async()=>{
            try {
                const response = await BaseSevice.create('deal/check', {dealId: Number(idRow)});
                if(response.status === 200) setCheckSuccess(true);
              } catch (err) {}
        }
        checkStatusDeals();

    }, [idRow]);
    const handelSubmit = async(status)=>{
        setIsProcessing(true); 
        try {
            const sales = user.role === 'PN' ? '' : sale;
            const response = await BaseSevice.create('deal/approved', {dealId: Number(idRow), status: status, note: note, sale: sales});
            if(response.status === 200){
                setCheckSuccess(true);
                setMessageSuccess(status+' thành công');
            }
          } catch (err) {}
          finally {
            setIsProcessing(false); // Reset processing state after completion
          }
    }
    const handeleApproved = ()=>{
        setShowNote(false);
        if(user.role === 'SM'){
           if(sale){
            handelSubmit('Approved')
           }else{
            alert('Vui lòng chọn Sale Name')
           }
        }else{
            handelSubmit('Approved')
        }
       
    }
    const handelReject = ()=>{
        setShowNote(true);
    }
    const handleChangeNote = (event)=>{
        setNote(event.target.value)
    }
    const handelSendNote = ()=>{
        if(!note){
            alert('Vui lòng nhập thông tin')
        }
        handelSubmit('Rejected')
    }
    const handleChangeSale = (event) =>{
        setSale(event.target.value)
    }
    if(mail !== user.email){
        window.location.href = '/';
    }
    return (
        <Container className="dashboard">
          <img src='/bg-dashboard.png' width="100%" alt='approved' className='banner-approved'/>
                <div className='approve-content'>
                <p>Dear <b>{user?.name || ''}</b></p>
                <p>We are pleased to inform you the following <strong>{dealDetail?.projectName || ''}</strong> has been approved:</p>
                <ul>
                    <li><strong>Partner Name:</strong> {dealDetail?.partnerName || ''}</li>
                    <li><strong>Company Name:</strong> {dealDetail?.companyName || ''}</li>
                    <li><strong>Primary Sale Contact Name:</strong> {dealDetail?.primarySaleName || ''}</li>
                    <li><strong>Primary Sale Contact Email:</strong> {dealDetail?.primarySaleEmail || ''}</li>
                    <li><strong>End Customer Company:</strong> {dealDetail?.endCustomerCompany || ''}</li>
                    <li><strong>Deal value:</strong> {dealDetail?.estimatedBudget || ''}</li>
                    <li><strong>Key Solution:</strong> {dealDetail?.keyProduct || ''}</li>
                    <li><strong>Status:</strong> {dealDetail?.status || ''}</li>
                </ul>
                {!checkSuccess ? (
                    <div>
                        {user.role === 'SM' ? (
                           <FormControl fullWidth sx={{ marginBottom: 2 }}>
                           <Grid container spacing={2} alignItems="center">
                             <Grid item xs={12} md={2}>
                               <Typography component="label">Select Sale Name  <span className='require-star'>*</span></Typography>
                             </Grid>
                             <Grid item xs={12} md={4}>
                             <Select
                                             fullWidth
                                             name='sale'
                                             value={sale || ''}
                                             size="small"
                                             onChange={handleChangeSale}
                                             >
                                             {saleLists.map(option => (
                                                 <MenuItem key={option.email} value={option.email}>{option.name} ({option.email})</MenuItem>
                                             ))}
                 
                                             </Select>
                 
                 
                             </Grid>
                           </Grid>
                         </FormControl>
                        ) : ''}

                    
                         <div className='buttom-status'>
                    <p className='btn-action btn-approved' onClick={handeleApproved} disabled={isProcessing}>{isProcessing ? 'Loading...' : 'Approved'}</p>
                    <p className='btn-action btn-reject' onClick={handelReject}>Rejects</p>
                    </div>
                        {shownote ?
                    <div className='note'>
                        <textarea name="note" rows={6} cols={40} className='txt-note' value={note} onChange={handleChangeNote}/>
                        <p className='btn-action btn-send' onClick={handelSendNote} disabled={isProcessing}>{isProcessing ? 'Loanding...' : 'Gửi'}</p>
                </div>
                
                 : ''}
                    </div>
                   
                ): (
                    <div className='approve-success'>
                    <img src='/icon-success.png' width="100%" alt='approved' className='banner-sucess'/>
                    <p className='title-success'>{messageSuccess}</p>
                    </div>
                )}
                
               
                
                
                </div>
           
          
           
        </Container>

    )
}
export default Approved