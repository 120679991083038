import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useUser } from "../contexts/UserContext";
import { useLocation } from 'react-router-dom';
const Layout = () => {
  const {user} = useUser();
  const location = useLocation();
  const isManagerDeal = location.pathname === '/partner/deals' || location.pathname === '/partner/deals/';
  const containerClass = isManagerDeal && user.role === "PN" || isManagerDeal && user.role === "SM" ? "" : "container";
  return (
  <div className={containerClass}>
    <Header />
    <div className="content">
      <Outlet />
    </div>
    <Footer />
  </div>
)};

export default Layout;
