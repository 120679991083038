import React, { useState, useEffect } from 'react';
import { AppBar, Tabs, Tab, Button, Box, TextField, FormControl, Container, Grid, Typography, Select, MenuItem, FormControlLabel, Checkbox, Snackbar, Alert } from '@mui/material';
import BaseSevice from "../services/Baseservice";
import { useUser } from '../contexts/UserContext';
import UnauthorizedPage from './UnauthorizedPage';
const tabForms = [
  {
    fields: [
      { name: 'partnerName', isRequired: true },
      { name: 'companyName', isRequired: true },
      { name: 'companyDomain', isRequired: true },
      { name: 'primarySaleName', isRequired: true },
      { name: 'primarySaleEmail', isRequired: true },
      { name: 'primarySalePhone', isRequired: true },
    ],
    renderForm: (formData, handleChange, companyOptions, partnerOptions) => (
      <Box>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Partner Name <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Select
                fullWidth
                name='partnerName'
                value={formData.partnerName || ''}
                size="small"
                onChange={handleChange}
              >
                {partnerOptions.map(option => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              </Select>


            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Company Name <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Select
                fullWidth
                name='companyName'
                value={formData.companyName || ''}
                size="small"
                onChange={handleChange}
              >
                {companyOptions.map(option => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              </Select>


            </Grid>
          </Grid>
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4} >
              <Typography component="label">Company Domain <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="companyDomain"
                value={formData.companyDomain || ''}
                onChange={handleChange}
                size="small"
              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Primary Sale Contact Name <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="primarySaleName"
                value={formData.primarySaleName || ''}
                onChange={handleChange}
                size="small"

              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Primary Sale Contact Email <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField

                fullWidth
                name="primarySaleEmail"
                value={formData.primarySaleEmail || ''}
                onChange={handleChange}
                size="small"

              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Primary Sale Contact Phone <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="primarySalePhone"
                value={formData.primarySalePhone || ''}
                onChange={handleChange}
                size="small"

              />
            </Grid>
          </Grid>
        </FormControl>
      </Box>

    ),
  },
  {
    fields: [
      { name: 'projectName', isRequired: true },
      { name: 'customerCompany', isRequired: true },
      { name: 'taxIndentification', isRequired: true },
      { name: 'location', isRequired: true },
      { name: 'repName', isRequired: true },
      { name: 'repRole', isRequired: true },
      { name: 'website', isRequired: true },
    ],
    renderForm: (formData, handleChange, companyOptions, partnerOptions, productOptions, solutionOptions, locationOptions) => (
      <Box>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Project Name <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="projectName"
                value={formData.projectName || ''}
                onChange={handleChange}

                size="small"

              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">End Customer Company <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="customerCompany"
                value={formData.customerCompany || ''}
                onChange={handleChange}

                size="small"

              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Tax Indentification No. <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="taxIndentification"
                value={formData.taxIndentification || ''}
                onChange={handleChange}
                type="number"
                size="small"
                inputProps={{ pattern: "[0-9]*" }} 
              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }} >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Location <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>

              <Select
                fullWidth
                name='location'
                value={formData.location || ''}
                size="small"
                onChange={handleChange}
              >
                {locationOptions.map(option => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Rep Name <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="repName"
                value={formData.repName || ''}
                onChange={handleChange}

                size="small"

              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Rep Role <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="repRole"
                value={formData.repRole || ''}
                onChange={handleChange}
                size="small"

              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Website or Company Domain<span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="website"
                value={formData.website || ''}
                onChange={handleChange}

                size="small"

              />
            </Grid>
          </Grid>
        </FormControl>
      </Box>



    ),
  },
  {
    fields: [
      { name: 'keyProduct', isRequired: true },
      { name: 'solution', isRequired: true },
      { name: 'description', isRequired: false },
      { name: 'estimatedBudget', isRequired: true },
      { name: 'approvedBudget', isRequired: true },
      { name: 'requestPoc', isRequired: false },
      { name: 'estimatedDate', isRequired: false },
      { name: 'projectCompetitor', isRequired: false },
      { name: 'ccto', isRequired: false },

    ],
    renderForm: (formData, handleChange, partnerOptions, companyOptions, productOptions, solutionOptions) => (
      <Box>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Key Product <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>

              <Select
                fullWidth
                name='keyProduct'
                value={formData.keyProduct || ''}
                size="small"
                onChange={handleChange}
              >
                {productOptions.map(option => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}

              </Select>
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Solution <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>

              <Select
                fullWidth
                name='solution'
                value={formData.solution || []}
                size="small"
                multiple
                onChange={handleChange}
              >
                {solutionOptions.map(option => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Description</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="description"
                value={formData.description || ''}
                onChange={handleChange}
                required
                size="small"

              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Estimated Project Budget <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="estimatedBudget"
                value={formData.estimatedBudget || ''}
                onChange={handleChange}
                required
                size="small"
                type="number"
                inputProps={{ pattern: "[0-9]*" }} 
              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Does the customer have approved budget? <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControlLabel sx={{ justifyContent: 'flex-end', display: 'flex' }} control={<Checkbox name='approvedBudget' checked={formData.approvedBudget || false} value={formData.approvedBudget} onChange={handleChange} />} label={formData.approvedBudget ? "Yes" : "No"} />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Request POC? </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControlLabel sx={{ justifyContent: 'flex-end', display: 'flex' }} control={<Checkbox name='requestPoc' checked={formData.requestPoc || false} value={formData.requestPoc} onChange={handleChange} />} label={formData.requestPoc ? "Yes" : "No"} />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Estimated Close Date <span className='require-star'>*</span></Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                type="date"
                name="estimatedDate"
                value={formData.estimatedDate || ''}
                onChange={handleChange}
                required
                InputLabelProps={{ shrink: true }} // Đảm bảo label hiển thị đúng cách
                size="small"
              />

            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">Project Competitor</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="projectCompetitor"
                value={formData.projectCompetitor || ''}
                onChange={handleChange}
                required
                size="small"

              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography component="label">CC to (Ex: user1@fpt.com, user2@fpt.com, ...)</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                name="ccto"
                value={formData.ccto || ''}
                onChange={handleChange}
                required
                size="small"

              />
            </Grid>
          </Grid>
        </FormControl>
      </Box>
    ),
  },
  {
    fields: [


    ],
    renderForm: (formData, handleChange) => (
      <Box>
        <FormControl fullWidth sx={{ marginBottom: 2, minHeight: 200 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <p>By submitting a completed Deal Registration Form, the partner agrees to all terms and conditions outlined in the Terms and Conditions. FPT Smart Cloud reserves the right to contact the end customer to verify any of the information submitted by the partner and to decline any opportunity registration.</p>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
    ),
  },
  {
    fields: [
      { name: 'acceptTerm', isRequired: true },
    ],
    renderForm: (formData, handleChange) => (
      <Box>
        <FormControl fullWidth sx={{ marginBottom: 2, minHeight: 200 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <p>Deal Registration eligibility requires the Partner and the Partner’s opportunity to meet the following criteria: </p>
              <ul className='list-term'>
                <li>Partner must accurately submit the Deal for review via Deal Registration tool.</li>
                <li>Each Deal Registration must represent a single deal with a single end-customer.</li>
                <li>Before submitting a deal for Deal Registration, the Partner must substantiate pre-sales efforts related to such deal, for example
                  meeting with the end-user’s decision-makers, qualifying the deal, helping the end-customer to quantify the project budget, or helping the
                  end-customer to define the project requirements. Partners must include a description of such efforts in the justification statement in the
                  Deal Registration tool. </li>
                <li>In a Public Tender the Deal Registration may be approved for up to three (3) Partners. </li>
                <li>Partner must be the first, or in a Public Tender among the first up to three (3), to submit accurate and complete deal information. </li>
                <li>FPT Smart Cloud will notify the Partner by e-mail that the deal has been registered or declined within three (3) business days,
                  but deals for enterprise customers may take longer due to additional validations. </li>
                <li>“Registration Term” means ninety (90) calendar days after the date a Deal Registration has been approved.</li>
              </ul>

            </Grid>
          </Grid>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Grid container spacing={2} alignItems="center">

            <Grid item xs={12} md={8}>
              <FormControlLabel control={<Checkbox name='acceptTerm' checked={formData.acceptTerm || false} value={formData.acceptTerm} onChange={handleChange} />} label="I accept the Terms and Conditions" />
            </Grid>
          </Grid>
        </FormControl>
      </Box>
    ),
  },
];

function Register() {

  const {user} = useUser();

  const [currentTab, setCurrentTab] = useState(0);
  const [formData, setFormData] = useState({
    approvedBudget: false,
    estimatedDate: '',
    solution: [],
    companyName: '',
    companyDomain: '',
    primarySaleName: user.name,
    primarySaleEmail: user.email,
    primarySalePhone: user.phone
  });
  const [companyOptions, setCompanyOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [solutionOptions, setSolutionOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [textNote, setTextNote] = useState('Vui lòng điền đủ các trường thông tin yêu cầu trước khi tiếp tục.');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleTabChange = (event, newValue) => {
    if (validateForm()) {
      setCurrentTab(newValue);
    }
  };
  const handleChange = (event) => {
    const { name, value, type, checked, multiple } = event.target;
    if (name === 'partnerName') {
      const item = partnerOptions.find(d => d.id === value);
      setCompanyOptions(item ? item.account || [] : [])
      formData.companyDomain = item.domain;
      setFormData({
        ...formData,
        [name]: value,
        companyName: '',
      });
      return;
    }
    if (name === 'keyProduct') {
      const itemSolution = productOptions.find(d => d.id === value);
      setSolutionOptions(itemSolution ? itemSolution.solution || [] : []);
      setFormData({
        ...formData,
        [name]: value,

      });
      return;
    }
    if (name === 'taxIndentification') {
      if (!/^\d*$/.test(value)) {
        setTextNote('Tax Indentification No phải là số');
        return; // Do nothing if the value is not a number
      }
      setFormData({
        ...formData,
        [name]: value,

      });
      return;
    }
    if (name === 'estimatedBudget') {
      if (!/^\d*$/.test(value)) {
        setTextNote('Estimated Project Budget phải là số');
        return; // Do nothing if the value is not a number
      }
      setFormData({
        ...formData,
        [name]: value,

      });
      return;
    }
   
    setFormData({
      ...formData,
      [name]: multiple
        ? Array.from(event.target.selectedOptions, option => option.value)
        : type === 'checkbox' ? checked : value,
      primarySaleEmail: user.email,
    });
  };

  const validateForm = () => {
    const currentForm = tabForms[currentTab];
    return currentForm.fields.every(field => {
      if (field.name === 'approvedBudget') {
        // Đối với trường checkbox, đảm bảo rằng đã được chọn
        return formData.approvedBudget === true;
      }
      if (field.name === 'estimatedDate') {
        // Đối với trường ngày, đảm bảo rằng đã được chọn
        return formData.estimatedDate !== '';
      }
      if (field.name === 'solution') {
        return formData.solution.length > 0;
      }
      return !field.isRequired || formData[field.name];
    });
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setIsSubmitting(true); // Disable submit button
      try {
        const res = await BaseSevice.create('deal', formData);
        if (res.status === 201) {
          window.location.href = '/partner/project';
        } else {
          alert('Failed');
        }
      } catch (err) {
        console.error('Error submitting form:', err);
      } finally {
        setIsSubmitting(false); // Enable submit button after submission attempt
      }
    }
  };
  const handleNext = () => {
    if (validateForm()) {
      handleTabChange(null, currentTab + 1);
    } else {
      setSnackbarMessage(textNote);
      setSnackbarOpen(true);
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const getPartnerLists = async () => {
    try {
      const res = await BaseSevice.getAll('product');
      setPartnerOptions(res.data.dataPartner);
      setProductOptions(res.data.dataProduct);
      setLocationOptions(res.data.locations);
    } catch (err) { console.error('Error fetching resources:', err); }
  }
  useEffect(() => {
    getPartnerLists();

  }, []);
  if(user.role === 'PN' || user.role === 'SM' || user.role === 'Sale'){
    return (
        <UnauthorizedPage/>
    )
  }
  return (
    <Container className='page-register'>

      <Box sx={{ width: '100%' }}>
        <Container maxWidth="lg">
          {/* Snackbar for showing messages */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            action={
              <Button color="inherit" onClick={handleSnackbarClose}>
                Close
              </Button>
            }
          >
            <Alert onClose={handleSnackbarClose} severity="error">
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <AppBar position="static">
            <Tabs value={currentTab} onChange={handleTabChange} aria-label="form tabs" className='tabName'>
              <Tab label="PARTNER INFORMATION" />
              <Tab label="CUSTOMER INFORMATION" />
              <Tab label="PROJECT INFORMATION" />
              <Tab label="LEGAL INFORMATION" />
              <Tab label="TERMS & CONDITIONS" />

            </Tabs>
          </AppBar>
        </Container>

        <Box sx={{ padding: 2 }}>
          <Container maxWidth="md" className='register-tab-content'>
            {tabForms[currentTab].renderForm(formData, handleChange, companyOptions, partnerOptions, productOptions, solutionOptions, locationOptions)}
          </Container>
          <Container maxWidth="lg" className='register-tab-content'>
            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
              {currentTab > 0 && (
                <Button
                  onClick={() => setCurrentTab(currentTab - 1)}
                  variant="contained"
                  color="primary"
                >
                  Back
                </Button>
              )}
              {currentTab < tabForms.length - 1 ? (
                <Button
                  onClick={handleNext}
                  variant="contained"
                  color="primary"
                  sx={{ marginLeft: 2 }}

                >
                  Next
                </Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  sx={{ marginLeft: 2 }}
                  disabled={!validateForm() || isSubmitting}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Container>
        </Box>

      </Box>
    </Container>

  );
}

export default Register;
