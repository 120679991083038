import React, { useEffect, useState } from "react";
import { Container, Grid} from '@mui/material';

const Footer = () => {

    return (
        <Container className="footer" maxWidth={false}>
            <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8}>
                <h5>Make by FCI ISDD Team</h5>
                
            </Grid>
            <Grid item xs={12} md={4} sx={{textAlign: "right"}}>
                <p>2024v0.2606</p>
            </Grid>
          </Grid>
       </Container>
    )
}
export default Footer