import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_BASE_URL_MICROSOFT } from "../config";

axios.defaults.withCredentials = false;

const LoginMicrosoft = () => {
  const stateStore = localStorage.getItem('state');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const handelCallback = async () => {
    let timedOut = false;

    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const timeoutId = setTimeout(() => {
      timedOut = true;
      setLoading(false);
      setError('Login error: Timeout exceeded');
    }, 10000); // 5 seconds
    if (code && stateStore === state) {
      await axios
        .post(API_BASE_URL_MICROSOFT, { code }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        .then((response) => {
          if (!timedOut) {
            const accessToken = response.data.accessToken;
            const refreshToken = response.data.refreshToken;
            localStorage.setItem('token', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.removeItem('state');
            window.location.href = '/';
          }
        })
        .catch((err) => {
          console.error('Error while exchanging code for access token:', err);
          if (!timedOut) {
            setError(`Login error: ${err.message}`);
          }
        })
        .finally(() => {
          if (!timedOut) {
            clearTimeout(timeoutId);
            setLoading(false);
          }
        });
    } else {
      // localStorage.clear();
      // localStorage.removeItem('state');
      window.location.href = '/';
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }
  useEffect(() => {
    handelCallback();
  });

  return (
    <div className="page-loading fc-box-center">
      {loading && <img src="/loading.gif" alt="Loading" width={150} />}
      {error && (
        <p>
          {error}
          . Please click
          <a href="/"> Return Login</a>
        </p>
      )}
    </div>
  );
};

export default LoginMicrosoft;
